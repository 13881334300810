@import "config";

.card {
  @include roundedBorder(lighten($text-green, 10%), 5px, 10px);
  background-color: $text-green;
  margin-bottom: 10px;
  box-shadow: 7px 7px 5px black;

  height: 30vh;
  display: flex;
  .card-Img {
    grid-area: "img";
    @include roundedBorder(lighten($text-green, 10%), 5px, 10px);
    box-shadow: 7px 7px 5px $background-gray;
    margin: 5px;
    width: 25%;
  }
  .rows {
    display: grid;
    margin: 5px;

    .card-Title {
      @include roundedBorder(lighten($text-green, 10%), 5px, 10px);
      background-color: lighten($text-green, 20%);
      box-shadow: 7px 7px 5px $background-gray;

      color: Black;
      font-weight: bold;
      font-size: 3vh;
    }
    .card-Desc {
      @include roundedBorder(lighten($text-green, 10%), 5px, 10px);
      background-color: lighten($text-green, 20%);
      box-shadow: 7px 7px 5px $background-gray;
      padding: 3px;
      min-height: 35%;
      overflow: hidden;
      font-weight: 800;
    }
    .card-Link {
      @include roundedBorder(lighten($text-green, 10%), 5px, 10px);
      background-color: lighten($text-green, 20%);
      box-shadow: 7px 7px 5px $background-gray;

      padding: 3%;
      height: 3%;
      overflow: hidden;
      display: inline-block;
      line-height: 0.5vh;
      font-weight: 800;
      font-size: 2vh;

      .link-link {
        font-weight: 800;
        font-size: 2vh;
        text-decoration: none;
        color: blue;
      }
    }
  }
}
