@import "config";
.pageHeader {
  .navBar {
    display: flex;
    justify-content: space-around;
    background-color: white;
    font-size: 3vh;
    .pageHeadings {
      font-weight: bold;
    }
    .pageIcons {
    }
  }
}
