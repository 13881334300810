@import "config";

.HeadingsList {
  @include horizontalList;
  .heading-button {
    background: none;
    border: none;
    font-size: 3vh;
  }
}
