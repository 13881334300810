@import "config";
.App {
  height: 100%;
  background-color: $background-gray;
  .App-header {
    overflow: hidden;
  }

  .App-body {
    height: 100%;
    overflow-y: auto;
    margin-top: 10px;
  }
}
