$background-gray: #464541;
$text-green: #8be74d;

@mixin roundedBorder($color, $thickness, $curve) {
  border-color: $color;
  border-radius: $curve;
  border-width: $thickness;
  border-style: solid;
}

@mixin hoverEffect($color) {
}

@mixin restList {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin horizontalList {
  @include restList;

  li {
    display: inline-block;
    margin: {
      left: -2px;
      right: 2em;
    }
  }
}
