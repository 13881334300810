@import "config";

.projectsHolder {
  .projects-Banner {
    @include roundedBorder(lighten($text-green, 10%), 5px, 10px);
    background-color: $text-green;

    width: 90%;
    margin: 0 auto;
    margin-bottom: 5px;

    text-align: center;
    font-weight: bold;
    font-size: 5vh;
  }
  .projectReel {
    padding: 0;
    list-style-type: none;
    width: 80%;
    height: 100%;
    margin: 0 auto;
  }
}
